<template>
    <icon-base :color="color" :size="size">
        <g transform="scale(1.7)">
            <path class="st1" d="M178.5,118.7c0,4.2,0,8.5,0,12.7c-0.3,0.1-0.2,0.3-0.3,0.5c-1,2.8-2.9,4.3-5.8,4.7c-2.4,0.3-4.8,0.6-7.1,0.9    c-0.7,0.1-1.1,0.3-1.3,1c-0.5,1.6-1.1,3.1-1.9,4.5c-0.3,0.6-0.2,0.9,0.2,1.4c1.5,1.9,3,3.8,4.5,5.8c2.4,3.1,2.2,6.5-0.6,9.3    c-2.2,2.2-4.4,4.4-6.6,6.6c-2.7,2.6-6.2,2.9-9.1,0.6c-2-1.5-3.9-3-5.8-4.6c-0.5-0.4-0.8-0.4-1.4-0.1c-1.5,0.8-3,1.4-4.6,1.9    c-0.6,0.2-0.9,0.5-0.9,1.2c-0.3,2.4-0.6,4.8-0.9,7.2c-0.4,2.8-1.9,4.8-4.6,5.8c-0.3,0.1-0.8-0.1-1,0.3c-4.2,0-8.4,0-12.5,0    c-0.1-0.3-0.3-0.2-0.5-0.3c-2.8-1-4.3-3-4.7-5.8c-0.3-2.4-0.6-4.8-0.9-7.2c-0.1-0.6-0.3-1-0.9-1.2c-1.6-0.5-3.1-1.1-4.6-1.9    c-0.6-0.3-0.9-0.2-1.4,0.2c-1.9,1.5-3.9,3.1-5.8,4.5c-3,2.3-6.5,2.1-9.1-0.6c-2.3-2.2-4.5-4.5-6.7-6.7c-2.7-2.7-2.9-6.1-0.6-9.1    c1.5-2,3.1-4,4.6-5.9c0.3-0.4,0.4-0.7,0.1-1.2c-0.8-1.5-1.5-3.1-2-4.8c-0.2-0.5-0.4-0.7-0.9-0.8c-2.4-0.3-4.8-0.6-7.1-0.9    c-3-0.3-5.1-1.8-6.1-4.7c-0.1-0.2,0-0.4-0.3-0.5c0-4.3,0-8.6,0-12.9c0.3-0.1,0.2-0.3,0.3-0.5c1-2.7,2.9-4.3,5.7-4.7    c2.4-0.3,4.8-0.6,7.2-0.9c0.7-0.1,1.1-0.3,1.3-1c0.5-1.6,1.1-3.1,1.9-4.5c0.3-0.6,0.2-0.9-0.2-1.4c-1.5-1.9-3.1-3.9-4.5-5.8    c-2.3-3-2.1-6.5,0.6-9.1c2.2-2.2,4.4-4.4,6.6-6.6c2.8-2.7,6.2-3,9.3-0.6c2,1.5,3.9,3,5.8,4.5c0.4,0.3,0.7,0.4,1.2,0.1    c1.5-0.8,3.1-1.5,4.8-2c0.6-0.2,0.8-0.5,0.8-1c0.3-2.4,0.6-4.8,0.9-7.2c0.5-3.8,3.2-6.2,7-6.2c3.3,0,6.5,0,9.8,0    c3.2,0,5.9,2.3,6.4,5.4c0.4,2.6,0.7,5.3,1,8c0.1,0.6,0.3,1,0.9,1.2c1.6,0.5,3.1,1.1,4.5,1.9c0.6,0.3,1,0.3,1.6-0.1    c1.8-1.5,3.7-2.9,5.6-4.4c3.2-2.5,6.6-2.3,9.5,0.6c2.2,2.2,4.3,4.3,6.5,6.5c2.8,2.8,3,6.2,0.6,9.3c-1.5,1.9-3,3.9-4.5,5.8    c-0.4,0.5-0.5,0.8-0.2,1.4c0.8,1.5,1.4,3,1.9,4.6c0.2,0.6,0.5,0.9,1.2,0.9c2.4,0.3,4.8,0.5,7.2,0.9c3.1,0.4,5.1,2.1,5.9,5.2    C178.3,118.6,178.4,118.7,178.5,118.7z M125.7,147.3c12.4-0.6,22.1-10.9,21.6-23c-0.6-12.4-10.9-22.1-23-21.6    c-12.4,0.6-22.1,10.9-21.6,23C103.2,138.1,113.5,147.6,125.7,147.3z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconPinterest',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>


